import { useEffect, useState } from 'react';
import {useDispatch} from "react-redux";
import axios, { apiUrl } from "../../../util/Api";
import { post, get } from "axios";
import firebase from "../../../util/firebase";
import {setInitUrl} from "../../../appRedux/actions";
import { decodeToken } from "react-jwt";

export const useProvideAuth = () => {
  const [authUser, setAuthUser] = useState(null);
  const [error, setError] = useState('');
  const [isLoadingUser, setLoadingUser] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchStart = () => {
    setLoading(true);
    setError('');
  };

  const fetchSuccess = () => {
    setLoading(false);
    setError('');
  };

  const fetchError = (error) => {
    setLoading(false);
    setError(error);
  };

  const userLogin = (user, callbackFun) => {
    fetchStart();
    //METHOD: POST
    //MODULE: LOGIN
    //DESCRIPTION: API LOGIN USER WITH USERNAME AND PASSWORD
    // post(`${apiUrl}/rest-auth/login/`, {
      post(`${apiUrl}/login/`, {
      username: user.username,
      password: user.password,
    })
      .then(({ data }) => {
        if (data) {
          fetchSuccess();
          // const token = data.key
          const token = data.access
          const refresh_token = data.refresh
          getAuthUser(token, refresh_token, user);
          // if (callbackFun) callbackFun();
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        fetchError(error.response.data.detail);
      });
  };

  // const userSignup = (user, callbackFun) => {
  //   fetchStart();
  //   axios
  //     .post('auth/register', user)
  //     .then(({ data }) => {
  //       if (data.result) {
  //         fetchSuccess();
  //         localStorage.setItem('token', data.token.access_token);
  //         axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
  //         getAuthUser();
  //         if (callbackFun) callbackFun();
  //       } else {
  //         fetchError(data.error);
  //       }
  //     })
  //     .catch(function (error) {
  //       fetchError(error.message);
  //     });
  // };

  // const sendPasswordResetEmail = (email, callbackFun) => {
  //   fetchStart();

  //   setTimeout(() => {
  //     fetchSuccess();
  //     if (callbackFun) callbackFun();
  //   }, 300);
  // };

  // const confirmPasswordReset = (code, password, callbackFun) => {
  //   fetchStart();

  //   setTimeout(() => {
  //     fetchSuccess();
  //     if (callbackFun) callbackFun();
  //   }, 300);
  // };

  // const renderSocialMediaLogin = () => null;

  const userSignOut = (callbackFun) => {
    fetchStart();
    // //METHOD: POST
    // //MODULE: LOGOUT
    // //DESCRIPTION: API LOGOUT USER
    // post(`${apiUrl}/rest-auth/logout/`, {
    //   key: localStorage.getItem("token"),
    // })
    //   .then(({ data }) => {
      
        // localStorage.removeItem('responder_id');

        //METHOD: SIGN OUT
        //DESCRIPTION: SIGN OUT USER IN FIREBASE
        firebase.auth().signOut().then(function () {
          fetchSuccess();
          setAuthUser(false);

            //METHOD: UPDATE
        //DESCRIPTION: FIREBASE UPDATE USER TO OFFLINE
        if(localStorage.getItem("user_role") === "Administrator"){
          //UPDATE ONLINE ADMINISTRATOR
          firebase.database().ref('Users').child('Administrator').child(localStorage.getItem("admin_firebase_id")).update({'online': '0'});
        }
        else if(localStorage.getItem("user_role") === "Management"){
          localStorage.removeItem("user_department");
          //UPDATE ONLINE MANAGEMENT
          firebase.database().ref('Users').child('Management').child(localStorage.getItem("admin_firebase_id")).update({'online': '0'});
        }
        else if(localStorage.getItem("user_role") === "Operation"){
          localStorage.removeItem("user_department");
          //UPDATE ONLINE OPERATION
          firebase.database().ref('Users').child('Operation').child(localStorage.getItem("admin_firebase_id")).update({'online': '0'});
        }
       
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("username");
        localStorage.removeItem("name");
        localStorage.removeItem("user_image");
        localStorage.removeItem("user_id");
        localStorage.removeItem("user_role");
        localStorage.removeItem("municipal_role");
        localStorage.removeItem("admin_firebase_id");
        axios.defaults.headers.common['Authorization'] = '';
        
           //SET INIT URL TO PATH "/"
           dispatch(setInitUrl('/'));
        })
          .catch(function (error) {
            fetchError(error.message);
          });
      // })
      // .catch(function (error) {
      //   fetchError(error.message);
      // });
  };

  const getAuthUser = (token, refresh_token, user) => {
    fetchStart();
    const user_details = decodeToken(token);
 
    const user_id = user_details.user_id;
    const email = user_details.email;
    const municipal_role = user_details.role__municipal;
    const role_name = user_details.role_name;
    const department = user_details.department;
    const name = user_details.first_name + " " + user_details.last_name;
    const user_image = user_details.image_url;
    
    //CHECK ROLES
    if (role_name === "Administrator" || role_name === "Management" || role_name === "Operation") {
      //METHOD: SIGN IN WITH EMAIL AND PASSWORD
      //MODULE: LOGIN
      //DESCRIPTION: FIREBASE LOGIN USER
      firebase.auth().signInWithEmailAndPassword(email, user.password)
        .then((fbaseUser) => {
          //CHECK IF EMAIL VERIFIED IN FIREBASE
          if (fbaseUser.user.emailVerified === true) {
            //METHOD: GET
            //DESCRIPTION: CHECK IF DEFAULT PASSWORD
            get(`${apiUrl}/api/check_default_password/?username=${user.username}`, {
              headers: {
                'Authorization': `Token ${token}`
              }
            })
              .then((res) => {
                //TRUE: USER MUST CHANGE DEFAULT PASSWORD
                if (res.data.message === true) {
                  localStorage.setItem("username", user.username);
                  fetchError(res.data.message);
                }
                else {
                  if(role_name === "Administrator"){
                    //FALSE: PROCEED TO DASHBOARD
                    //METHOD: SET
                    //DESCRIPTION: FIREBASE SET USER ONLINE
                    firebase.database().ref("Users/Administrator/" + fbaseUser.user.uid)
                    .set({
                      online: "1",
                      id: fbaseUser.user.uid
                    }, function (error) {
                      if (error) {
                        fetchError("Failed to login. Please try again.");
                      }
                      else {
                        localStorage.setItem("token", token);
                        localStorage.setItem("refresh_token", refresh_token);
                        localStorage.setItem("username", user.username);
                        localStorage.setItem("name", name);
                        localStorage.setItem("user_image", user_image);
                        localStorage.setItem("user_id", user_id);
                        localStorage.setItem("user_role", role_name);
                        localStorage.setItem("municipal_role", municipal_role);
                        localStorage.setItem("admin_firebase_id", fbaseUser.user.uid);
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                        // localStorage.removeItem('responder_id');

                        fetchSuccess();
                        setAuthUser(user_details);
                      }
                    });
                  }
                  else if(role_name === "Management"){
                    //FALSE: PROCEED TO DASHBOARD
                    //METHOD: SET
                    //DESCRIPTION: FIREBASE SET USER ONLINE
                    firebase.database().ref("Users/Management/" + fbaseUser.user.uid)
                    .set({
                      online: "1",
                      id: fbaseUser.user.uid
                    }, function (error) {
                      if (error) {
                        fetchError("Failed to login. Please try again.");
                      }
                      else {
                        localStorage.setItem("token", token);
                        localStorage.setItem("refresh_token", refresh_token);
                        localStorage.setItem("username", user.username);
                        localStorage.setItem("name", name);
                        localStorage.setItem("user_image", user_image);
                        localStorage.setItem("user_id", user_id);
                        localStorage.setItem("user_role", role_name);
                        localStorage.setItem("user_department", department);
                        localStorage.setItem("municipal_role", municipal_role);
                        localStorage.setItem("admin_firebase_id", fbaseUser.user.uid);
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                        // localStorage.removeItem('responder_id');

                        fetchSuccess();
                        setAuthUser(user_details);
                      }
                    });
                  }
                  else if(role_name === "Operation"){
                    //FALSE: PROCEED TO DASHBOARD
                    //METHOD: SET
                    //DESCRIPTION: FIREBASE SET USER ONLINE
                    firebase.database().ref("Users/Operation/" + fbaseUser.user.uid)
                    .set({
                      online: "1",
                      id: fbaseUser.user.uid
                    }, function (error) {
                      if (error) {
                        fetchError("Failed to login. Please try again.");
                      }
                      else {
                        localStorage.setItem("token", token);
                        localStorage.setItem("refresh_token", refresh_token);
                        localStorage.setItem("username", user.username);
                        localStorage.setItem("name", name);
                        localStorage.setItem("user_image", user_image);
                        localStorage.setItem("user_id", user_id);
                        localStorage.setItem("user_role", role_name);
                        localStorage.setItem("user_department", department);
                        localStorage.setItem("municipal_role", municipal_role);
                        localStorage.setItem("admin_firebase_id", fbaseUser.user.uid);
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                        // localStorage.removeItem('responder_id');

                        fetchSuccess();
                        setAuthUser(user_details);
                      }
                    });
                  }
                  else{
                    fetchError("Invalid credentials. Restricted access.");
                  }
                }
              })
          }
          else {
            //SEND EMAIL VERIFICATION
            fetchError(fbaseUser.user.emailVerified);
          }
        })
        .catch((error) => {
          fetchError(error.message);
        });
    }
    else {
      fetchError("Invalid credentials. Restricted access.");
    }

    // //METHOD: GET
    // //DESCRIPTION: GET USER DETAILS
    // get(`${apiUrl}/api/profile_view/?username=${user.username}`, {
    //   headers: {
    //     'Authorization': `Token ${token}`
    //   }
    // })
    //   .then(({ data }) => {
    //     data.map((detail) => {
    //       const user_id = detail.id;
    //       const email = detail.email;
    //       const municipal_role = detail.role__municipal;
    //       const role_name = detail.role__name;
    //       const department = detail.department;
    //       const name = detail.first_name + " " + user.last_name;
    //       const user_image = detail.image_url;

    //       if (role_name === "Administrator" || role_name === "Management" || role_name === "Operation") {
    //         //METHOD: SIGN IN WITH EMAIL AND PASSWORD
    //         //MODULE: LOGIN
    //         //DESCRIPTION: FIREBASE LOGIN USER
    //         firebase.auth().signInWithEmailAndPassword(email, user.password)
    //           .then((fbaseUser) => {
    //             //CHECK IF EMAIL VERIFIED IN FIREBASE
    //             if (fbaseUser.user.emailVerified === true) {
    //               //METHOD: GET
    //               //DESCRIPTION: CHECK IF DEFAULT PASSWORD
    //               get(`${apiUrl}/api/check_default_password/?username=${user.username}`, {
    //                 headers: {
    //                   'Authorization': `Token ${token}`
    //                 }
    //               })
    //                 .then((res) => {
    //                   //TRUE: USER MUST CHANGE DEFAULT PASSWORD
    //                   if (res.data.message === true) {
    //                     localStorage.setItem("username", user.username);
    //                     fetchError(res.data.message);
    //                   }
    //                   else {
    //                     if(role_name === "Administrator"){
    //                       //FALSE: PROCEED TO DASHBOARD
    //                       //METHOD: SET
    //                       //DESCRIPTION: FIREBASE SET USER ONLINE
    //                       firebase.database().ref("Users/Administrator/" + fbaseUser.user.uid)
    //                       .set({
    //                         online: "1",
    //                         id: fbaseUser.user.uid
    //                       }, function (error) {
    //                         if (error) {
    //                           fetchError("Failed to login. Please try again.");
    //                         }
    //                         else {
    //                           localStorage.setItem("token", token);
    //                           localStorage.setItem("username", user.username);
    //                           localStorage.setItem("name", name);
    //                           localStorage.setItem("user_image", user_image);
    //                           localStorage.setItem("user_id", user_id);
    //                           localStorage.setItem("user_role", role_name);
    //                           localStorage.setItem("municipal_role", municipal_role);
    //                           localStorage.setItem("admin_firebase_id", fbaseUser.user.uid);
    //                           axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    //                           // localStorage.removeItem('responder_id');

    //                           fetchSuccess();
    //                           setAuthUser(data);
    //                         }
    //                       });
    //                     }
    //                     else if(role_name === "Management"){
    //                       //FALSE: PROCEED TO DASHBOARD
    //                       //METHOD: SET
    //                       //DESCRIPTION: FIREBASE SET USER ONLINE
    //                       firebase.database().ref("Users/Management/" + fbaseUser.user.uid)
    //                       .set({
    //                         online: "1",
    //                         id: fbaseUser.user.uid
    //                       }, function (error) {
    //                         if (error) {
    //                           fetchError("Failed to login. Please try again.");
    //                         }
    //                         else {
    //                           localStorage.setItem("token", token);
    //                           localStorage.setItem("username", user.username);
    //                           localStorage.setItem("name", name);
    //                           localStorage.setItem("user_image", user_image);
    //                           localStorage.setItem("user_id", user_id);
    //                           localStorage.setItem("user_role", role_name);
    //                           localStorage.setItem("user_department", department);
    //                           localStorage.setItem("municipal_role", municipal_role);
    //                           localStorage.setItem("admin_firebase_id", fbaseUser.user.uid);
    //                           axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    //                           // localStorage.removeItem('responder_id');

    //                           fetchSuccess();
    //                           setAuthUser(data);
    //                         }
    //                       });
    //                     }
    //                     else if(role_name === "Operation"){
    //                       //FALSE: PROCEED TO DASHBOARD
    //                       //METHOD: SET
    //                       //DESCRIPTION: FIREBASE SET USER ONLINE
    //                       firebase.database().ref("Users/Operation/" + fbaseUser.user.uid)
    //                       .set({
    //                         online: "1",
    //                         id: fbaseUser.user.uid
    //                       }, function (error) {
    //                         if (error) {
    //                           fetchError("Failed to login. Please try again.");
    //                         }
    //                         else {
    //                           localStorage.setItem("token", token);
    //                           localStorage.setItem("username", user.username);
    //                           localStorage.setItem("name", name);
    //                           localStorage.setItem("user_image", user_image);
    //                           localStorage.setItem("user_id", user_id);
    //                           localStorage.setItem("user_role", role_name);
    //                           localStorage.setItem("user_department", department);
    //                           localStorage.setItem("municipal_role", municipal_role);
    //                           localStorage.setItem("admin_firebase_id", fbaseUser.user.uid);
    //                           axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    //                           // localStorage.removeItem('responder_id');

    //                           fetchSuccess();
    //                           setAuthUser(data);
    //                         }
    //                       });
    //                     }
    //                     else{
    //                       fetchError("Invalid credentials. Restricted access.");
    //                     }
    //                   }
    //                 })
    //             }
    //             else {
    //               //SEND EMAIL VERIFICATION
    //               fetchError(fbaseUser.user.emailVerified);
    //             }
    //           })
    //           .catch((error) => {
    //             fetchError(error.message);
    //           });
    //       }
    //       else {
    //         fetchError("Invalid credentials. Restricted access.");
    //       }
    //     })
    //   })
    //   .catch(function (error) {
    //     fetchError(error.message);
    //   });
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      // console.log("useEffect triggers with token")
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      //METHOD: GET
      //DESCRIPTION: GET USER DETAILS
      get(`${apiUrl}/api/profile_view/?username=${localStorage.getItem('username')}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(({ data }) => {
          if (data) {
            setAuthUser(data);
          }
          setLoadingUser(false);
        })
        .catch(function () {
          localStorage.removeItem("token");
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("username");
          localStorage.removeItem("name");
          localStorage.removeItem("user_image");
          localStorage.removeItem("user_id");
          localStorage.removeItem("user_role");
          localStorage.removeItem("user_department");
          localStorage.removeItem("municipal_role");
          localStorage.removeItem("admin_firebase_id");
          axios.defaults.headers.common['Authorization'] = '';
          setLoadingUser(false);
        });
    }
    else{
      // console.log("useEffect triggers without token")
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("username");
      localStorage.removeItem("name");
      localStorage.removeItem("user_image");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_role");
      localStorage.removeItem("user_department");
      localStorage.removeItem("municipal_role");
      localStorage.removeItem("admin_firebase_id");
      axios.defaults.headers.common['Authorization'] = '';
      setLoadingUser(false);
    }
  }, []);

  // Return the user object and auth methods
  return {
    isLoadingUser,
    isLoading,
    authUser,
    error,
    setError,
    setAuthUser,
    getAuthUser,
    userLogin,
    // userSignup,
    userSignOut,
    // renderSocialMediaLogin,
    // sendPasswordResetEmail,
    // confirmPasswordReset,
  };
};
