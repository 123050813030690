import React, { Component } from "react";
import { Row, Col, Form, Input, Button, Divider, Modal, message } from "antd";
import axios from "../util/Api";
import firebase from "../util/firebase";
import { withRouter } from "react-router-dom";

class ChangeDefaultPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      viewChangePassword: true
    };
  }

  handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  onToggleDefaultPassword = () =>{
    this.setState({
      viewChangePassword: !this.state.viewChangePassword
    })
  }

  success = () => {
    message.config({
      top: 65,
    });
    message
      .loading("Action in progress..", 2)
      .then(() => message.success("Password Successfully Changed! Please re-login to a new password.", 2.5));
  };

  fail = () => {
    message.config({
      top: 65,
    });
    message
      .loading("Action in progress..", 2)
      .then(() => message.error("Oops! Invalid form. Please try again.", 2.5));
  };

  handleFormSubmit = values => {
    const username = localStorage.getItem("username");

    this.setState({
      loadingButton: true
    })

    //METHOD: POST
    //MODULE: CHANGE PASSWORD
    //DESCRIPTION: CHANGE PASSWORD 
    axios.post('api/change_password/', {
      username: username,
      old_password: values.defaultPassword,
      new_password: values.newPassword,
      confirm_password: values.confirmPassword
    })
    .then(res=>{
      var passwordChange = false
      var user = firebase.auth().currentUser;
      //RE-AUTHENTICATED
      //CHANGE PASSWORD
      var credentials = firebase.auth.EmailAuthProvider.credential(user.email, values.defaultPassword)
      user.reauthenticateWithCredential(credentials).then(function() {
        user.updatePassword(values.newPassword).then(function() {
          passwordChange = true
        }).catch(function(error) {
          
          passwordChange = false
        });
      }).catch(function(error) {
          passwordChange = false
      });

      setTimeout(() => {
        if(passwordChange == true){
          this.setState({
            loadingButton: false
          })
          this.onToggleDefaultPassword()
          localStorage.removeItem("username");
          //FIREBASE SIGN OUT
          firebase.auth().signOut().then(function() {
          })
          .catch(function(error) {
          });
          this.success();
          setTimeout(() => {
           window.location.reload()
          }, 4000);
        }
        else{
          this.fail();
          this.setState({
            loadingButton: false
          })
        }
      }, 3000);
    })
    .catch(res=>{
      this.fail();
      this.setState({
        loadingButton: false
      })
      }
    )
  };

  render() {
    
    return (
      <Modal
      closable={false}
      title="Change Default Password"
      visible={this.state.viewChangePassword}
      footer={null}
      destroyOnClose
    >
    <Form
      onFinish={this.handleFormSubmit}
      // onFinishFailed={(e) => this.handleFormSubmit(e)}
    >
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
            name="defaultPassword"
            label="Default Password"
            onKeyDown={this.handleKeyDown}
            rules={[
              {
                required: true,
                message: 'Please input your current password!',
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label="New Password"
            onKeyDown={this.handleKeyDown}
            rules={[
              {
                required: true,
                message: 'Please input your new password!',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  var reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/
                  if (!value || reg.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Atleast 8 characters, one numeric, one lowercase, one uppercase, one special character and no spaces');
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={['newPassword']}
            onKeyDown={this.handleKeyDown}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('The two passwords that you entered do not match!');
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <div layout="inline" className="gx-text-right">
        <Button
          key="reset"
          type="danger"
          ghost
          size="small"
          onClick={this.onToggleDefaultPassword}
          ghost
        >
          Cancel
        </Button>
        <Button 
          htmlType="submit"
          type="primary" size="small"
        loading={this.state.loadingButton}
        >
          Submit
        </Button>
      </div>
    </Form>
    </Modal>
    );
  }
}

export default withRouter((ChangeDefaultPassword));
