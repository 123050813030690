import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Redirect, Route, Switch, useHistory, useLocation, useRouteMatch, BrowserRouter} from "react-router-dom";
import {ConfigProvider, Modal} from 'antd';
import {IntlProvider} from "react-intl";
import IdleTimer from 'react-idle-timer';
import axios from "../../util/Api";
import AppLocale from "../../lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import ForgotPassword from "../ForgotPassword";
// import UnderMaintenance from "../UnderMaintenance";
import {setInitUrl} from "../../appRedux/actions";
import SessionTime from "./SessionTimeout";
// import moment from "moment";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK
} from "../../constants/ThemeSetting";
import CircularProgress from "../../components/CircularProgress";
import {useAuth} from "../../authentication";

const RestrictedRoute = ({component: Component, location, authUser, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            // pathname: '/under-maintenance',
            state: {from: location}
          }}
        />}
  />;

const setLayoutType = (layoutType) => {
  if (layoutType === LAYOUT_TYPE_FULL) {
    document.body.classList.remove('boxed-layout');
    document.body.classList.remove('framed-layout');
    document.body.classList.add('full-layout');
  } else if (layoutType === LAYOUT_TYPE_BOXED) {
    document.body.classList.remove('full-layout');
    document.body.classList.remove('framed-layout');
    document.body.classList.add('boxed-layout');
  } else if (layoutType === LAYOUT_TYPE_FRAMED) {
    document.body.classList.remove('boxed-layout');
    document.body.classList.remove('full-layout');
    document.body.classList.add('framed-layout');
  }
};

const setNavStyle = (navStyle) => {
  if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
    navStyle === NAV_STYLE_DARK_HORIZONTAL ||
    navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
    navStyle === NAV_STYLE_ABOVE_HEADER ||
    navStyle === NAV_STYLE_BELOW_HEADER) {
    document.body.classList.add('full-scroll');
    document.body.classList.add('horizontal-layout');
  } else {
    document.body.classList.remove('full-scroll');
    document.body.classList.remove('horizontal-layout');
  }
};

const App = () => {
  const locale = useSelector(({settings}) => settings.locale);
  const navStyle = useSelector(({settings}) => settings.navStyle);
  const layoutType = useSelector(({settings}) => settings.layoutType);
  const themeType = useSelector(({settings}) => settings.themeType);
  const isDirectionRTL = useSelector(({settings}) => settings.isDirectionRTL);
  const initURL = useSelector(({settings}) => settings.initURL);

  const {authUser, isLoadingUser, userSignOut} = useAuth();
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    if (isDirectionRTL) {
      document.documentElement.classList.add('rtl');
      document.documentElement.setAttribute('data-direction', 'rtl')
    } else {
      document.documentElement.classList.remove('rtl');
      document.documentElement.setAttribute('data-direction', 'ltr')
    }
  }, [isDirectionRTL]);

  useEffect(() => {
    if (locale)
      document.documentElement.lang = locale.locale;
  }, [locale]);

  useEffect(() => {
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add('dark-theme');
    } else if (document.body.classList.contains('dark-theme')) {
      document.body.classList.remove('dark-theme');
    }
  }, [themeType]);

  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
  });

  useEffect(() => {
    if (!isLoadingUser) {
      if (!authUser) {
        history.push('/signin');
        // history.push('/under-maintenance');
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
      // } else if (initURL === '' || initURL === '/' || initURL === '/under-maintenance') {
        history.push('/dashboard');
        // history.push('/under-maintenance');
      } else {
        history.push(initURL);
      }
    }
  }, [isLoadingUser, authUser, initURL, history]);

  useEffect(() => {
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [layoutType, navStyle]);

  const currentAppLocale = AppLocale[locale.locale];

  var idleTimer = null;
  
  const [sess, setSession] = useState(false);

  function toggleSession(e){
    setSession(!sess)
  }

  function onAction(e) {
    // console.log('user did something', e)
    // console.log('time remaining action', idleTimer.getRemainingTime())
    // var tempTime = moment.duration(idleTimer.getRemainingTime());
    // var y = tempTime.minutes() + tempTime.seconds();
    // console.log('time remaining action', y)
  }

  function onActive(e) {
    // console.log('user is active', e)
    // console.log('time remaining', idleTimer.getRemainingTime())
    // var millis = idleTimer.getRemainingTime();
    // var minutes = Math.floor(millis / 60000);
    // var seconds = ((millis % 60000) / 1000).toFixed(0);
    // var time = minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
    //   console.log('time remaining', time)
    
    // var tempTime = moment.duration(idleTimer.getRemainingTime());
    // var y = tempTime.minutes() + tempTime.seconds();
    //   console.log('time remaining', y)
  }

  function onIdle(e) {
    // console.log('user is idle', e)
    // console.log('last active', idleTimer.getRemainingTime())
    // var millis = idleTimer.getRemainingTime();
    // // const y = moment.duration(moment(idleTimer.getRemainingTime(), "hh:mm:ss").format("hh:mm:ss"))
    // var minutes = Math.floor(millis / 60000);
    // var seconds = ((millis % 60000) / 1000).toFixed(0);
    // var time = minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
    //   console.log('last active', time)
    //DESCRIPTION: LOGOUT USER
    // userSignOut(() => {
    //   history.push('/');
    // });

    const token = localStorage.getItem('token');

    if(token){
      toggleSession();
    }
    // alert("timeout!!!")
    
  }

  const [timeoutValue, setTimeoutValue] = useState(0); 
  const [countdownValue, setCountdownValue] = useState(0)

  useEffect(() => {
    axios.get('api/get_system_timeout')
    .then(res =>{
      // let minutes = (res.data / (1000 * 60)).toFixed(1);
      // console.log(1000 * 60 * 5)
      setTimeoutValue(res.data[0].value)
      setCountdownValue(res.data[1].value)
   })
  }, []);


  return isLoadingUser ? <CircularProgress/> : (
    <>
      <IdleTimer
      ref={ref => { idleTimer = ref }}
      onActive={onActive}
      onIdle={onIdle}
      onAction={onAction}
      debounce={250}
      timeout={(1000 * 60 * timeoutValue)} />
      {/* // // timeout={(1000 * 60 * 2)} />
      // timeout={60000} /> */}
      {/* // timeout={(timeoutValue.value)} /> */}

      <ConfigProvider locale={currentAppLocale.antd} direction={isDirectionRTL ? 'rtl' : 'ltr'}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>
            <Switch>
              <Route exact path='/signin' component={SignIn}/>
              <Route exact path='/signup' component={SignUp}/>
              <Route exact path='/forgot-password' component={ForgotPassword} />
              {/* <Route exact path='/under-maintenance' component={UnderMaintenance}/> */}
              <BrowserRouter>
                <RestrictedRoute path={`${match.url}`} authUser={authUser} location={location} component={MainApp}/>
              </BrowserRouter>
              {/* <Redirect to ="/under-maintenance" /> */}
            </Switch>
        </IntlProvider>
      </ConfigProvider>
      <Modal
        closable={false}
        title="Session Expire"
        visible={sess}
        footer={null}
        destroyOnClose
        width={550}
      >
        <SessionTime toggleSession={toggleSession} countdownValue={countdownValue} />
      </Modal>
    </>
  )
};

export default App;
