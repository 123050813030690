import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import asyncComponent from "util/asyncComponent";

// var userRole
// setTimeout(() => {
//   // window.location.reload();
//   userRole = localStorage.getItem('user_role');
// }, 500);

// console.log(userRole)
// const userRole = localStorage.getItem('user_role');
const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        path={`${match.url}my-account`}
        component={asyncComponent(() =>
          import("../containers/Sidebar/Account")
        )}
      />
      <Route
        path={`${match.url}dashboard`}
        component={asyncComponent(() => import("./Dashboard"))}
      />

      {/* {userRole === "Administrator" ?
        <> */}
      {/* ADMINISTRATOR */}
      <Route
        path={`${match.url}cs-dashboard`}
        component={asyncComponent(() => import("./CustomerSupport"))}
      />
      <Route
        path={`${match.url}cs-view`}
        component={asyncComponent(() => import("./CustomerSupport/viewCS"))}
      />
      <Route
        path={`${match.url}user-management`}
        component={asyncComponent(() => import("./UserManagement"))}
      />
      <Route
        path={`${match.url}user-view`}
        component={asyncComponent(() => import("./UserManagement/viewUser"))}
      />
      <Route
        path={`${match.url}operation-management/asset-management`}
        component={asyncComponent(() =>
          import("./InventoryManagement/Inventories")
        )}
      />
      <Route
        path={`${match.url}customer-support-reports`}
        component={asyncComponent(() => import("./ReportManagement/Reports"))}
      />
      <Route
        path={`${match.url}system-management/incidents`}
        component={asyncComponent(() =>
          import("./SystemManagement/Categories")
        )}
      />
      <Route
        path={`${match.url}incident-details`}
        component={asyncComponent(() =>
          import("./SystemManagement/Categories/viewCategory")
        )}
      />
      <Route
        path={`${match.url}system-management/barangays`}
        component={asyncComponent(() => import("./SystemManagement/Barangays"))}
      />
      <Route
        path={`${match.url}system-management/departments`}
        component={asyncComponent(() =>
          import("./SystemManagement/Departments")
        )}
      />
      <Route
        path={`${match.url}system-management/stations`}
        component={asyncComponent(() => import("./SystemManagement/Stations"))}
      />
      <Route
        path={`${match.url}operation-management/asset-classifications`}
        component={asyncComponent(() =>
          import("./SystemManagement/Classifications")
        )}
      />
      <Route
        path={`${match.url}system-management/status`}
        component={asyncComponent(() => import("./SystemManagement/Status"))}
      />
      <Route
        path={`${match.url}system-management/roles`}
        component={asyncComponent(() => import("./SystemManagement/Roles"))}
      />
      <Route
        path={`${match.url}system-management/system-variables`}
        component={asyncComponent(() =>
          import("./SystemManagement/SystemVariable")
        )}
      />
      {/* </>
      : userRole === "Management" ?
        <> */}
      {/* MANAGEMENT */}
      <Route
        path={`${match.url}reports`}
        component={asyncComponent(() =>
          import("./ReportManagement/ResponderReport")
        )}
      />
      <Route
        path={`${match.url}report-details`}
        component={asyncComponent(() =>
          import("./ReportManagement/ReportDetails")
        )}
      />
      {/* </>
      : userRole === "Operation" ?
        <> */}
      {/* OPERATION */}
      <Route
        path={`${match.url}responder-dashboard`}
        component={asyncComponent(() => import("./Responders"))}
      />
      <Route
        path={`${match.url}responder-details`}
        component={asyncComponent(() =>
          import("./Responders/ResponderList/viewResponder")
        )}
      />
      <Route
        path={`${match.url}operation-management/asset-management`}
        component={asyncComponent(() =>
          import("./InventoryManagement/Inventories")
        )}
      />
      <Route
        path={`${match.url}operation-management/stations`}
        component={asyncComponent(() => import("./SystemManagement/Stations"))}
      />
      {/* </>

      :  <Route path={`${match.url}page-not-found`} component={asyncComponent(() => import('./PageNotFound'))} />
    
    } */}
    </Switch>
  </div>
);

export default App;
