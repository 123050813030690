import React, { Component } from "react";
import { Button, Divider, Form, Input, message } from "antd";
import IntlMessages from "util/IntlMessages";
import SFLogo from "../assets/images/sf_logo.svg";
import axios from "../util/Api";
import firebase from "../util/firebase";

const FormItem = Form.Item;

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: null,
      emailDisabled: false,
      loadingProceed: false,
      loadingSubmit: false,
      proceedHidden: false,
      newPasswordHidden: true
    };
  }

  success = () => {
    message.config({
      top: 65,
    });
    message
      .loading("Action in progress..", 2)
      .then(() => message.success("A verification link has been sent to your email. Thank you.", 2.5));
  };

  fail = (errorMessage) => {
    message.config({
      top: 65,
    });
    message
      .loading("Action in progress..", 2)
      .then(() => message.error(errorMessage, 2.5));
  };

  handleFormProceed = values => {

    this.setState({
      loadingProceed: true
    })

    //METHOD: POST
    //MODULE: FORGOT PASSWORD MODULE
    //DESCRIPTION: CHECK USER EMAIL
    axios.post(`api/check_user_email/`, {
      email: values.email
    })
      .then(res => {
        if (res.data.message == true) {
          this.setState({
            email: values.email,
            emailDisabled: true,
            loadingProceed: false,
            proceedHidden: true,
            newPasswordHidden: false
          })
        }
        else {
          this.fail("Your Email Address was not found on the system. Please contact your supervisor.");
          this.setState({
            loadingProceed: false
          })
        }
      })
      .catch(res => {
        this.fail("Oops! Invalid form. Please try again.")
        this.setState({
          loadingProceed: false
        })
      }
      )
  };

  handleFormSubmit = values => {
    this.setState({
      loadingSubmit: true
    })

    //METHOD: POST
    //MODULE: FORGOT PASSWORD MODULE
    //DESCRIPTION: FORGOT PASSWORD 
    axios.post('api/forgot_password/', {
      email: this.state.email,
      new_password: values.newPassword,
      confirm_password: values.confirmPassword
    })
    .then(res=>{
      var passwordChange = false
      var errorMessage = null
      var auth = firebase.auth();
      var emailAddress = this.state.email

      //FIREBASE RESET PASSWORD
      auth.sendPasswordResetEmail(emailAddress).then(function() {
          passwordChange = true
        }).catch(function(error) {
          passwordChange = false
          errorMessage = error
        });

      setTimeout(() => {
        if(passwordChange == true){
          this.setState({
            loadingSubmit: false
          })
          this.success();
          setTimeout(() => {
            this.props.history.push({
              pathname: "/signin",
            })
          }, 4000);
        }
        else{
          this.fail(errorMessage);
          this.setState({
            loadingSubmit: false
          })
        }
      }, 3000);
    })
    .catch(res=>{
      this.fail(res.data.message);
      this.setState({
        loadingSubmit: false
      })
      }
    )
  };

  render() {
    return (
      <div className="gx-login-container loginBackground">
        <div className="gx-login-content">
          <div className="gx-login-header gx-text-center gx-mb-5">
            <img src={SFLogo} alt="erp" title="erp" width="40%" className="gx-mb-3" />
            <h2>City of San Fernando</h2>
            <h3>Emergency Response Portal</h3>
          </div>
          <div className="gx-mb-4">
            <h2>Forgot Your Password ?</h2>
            <p><IntlMessages id="app.userAuth.forgot" /></p>
          </div>
          <Form
            initialValues={{ remember: true }}
            name="basic"
            onFinish={this.handleFormProceed}
            className="gx-signin-form gx-form-row0">
            <FormItem name="email" rules={[{ required: true, message: 'Please input your Email!' }]}>
              <Input className='gx-input-lineheight' type="email" placeholder="Email Address" disabled={this.state.emailDisabled} />
            </FormItem>
            <FormItem>
              <Button type="primary" className="gx-text-right" htmlType="submit" size="small" hidden={this.state.proceedHidden} loading={this.state.loadingProceed}>
                Proceed
              </Button>
            </FormItem>
          </Form>
          <div hidden={this.state.newPasswordHidden}>
            <Divider />
            <h4 className="gx-mb-4">Enter your new password here: </h4>
            <Form onFinish={this.handleFormSubmit}>
              <Form.Item
                name="newPassword"
                label="New Password"
                onKeyDown={this.handleKeyDown}
                rules={[
                  {
                    required: true,
                    message: 'Please input your new password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      var reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/
                      if (!value || reg.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject('Atleast 8 characters, one numeric, one lowercase, one uppercase, one special character and no spaces');
                    },
                  }),
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                dependencies={['newPassword']}
                onKeyDown={this.handleKeyDown}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject('The two passwords that you entered do not match!');
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary" size="small"
                  loading={this.state.loadingSubmit}
                >
                  Submit
                  </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
