import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom'
import { PhoneOutlined, ReconciliationOutlined, ApartmentOutlined, TeamOutlined } from "@ant-design/icons";
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common.pathname);
  const location = useLocation();

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];
  const userRole = localStorage.getItem('user_role');

  return (
    <>
      <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile />
          {/* <AppsNavigation /> */}
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">
            {
              userRole === "Administrator" ?
                <>
                  <Menu.Item key="dashboard">
                    <Link style={{ color: location.pathname === '/dashboard' ? '#fa8c15' : '' }}  to="/dashboard">
                      <i className='icon icon-dasbhoard' />
                      <span><IntlMessages id="sidebar.dashboard" /></span>
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="user-management">
                    <Link style={{ color: location.pathname === '/user-management' ? '#fa8c15' : '' }} to="/user-management">
                      <TeamOutlined style={{ color: location.pathname == '/user-management' ? '#fa8c15' : '', fontSize: '16px' }} />
                      <span className={location.pathname == '/user-management' ? 'menuItem' : ''}><IntlMessages id="sidebar.userManagement" /></span>
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="cs-dashboard">
                    <Link style={{ color: location.pathname === '/cs-dashboard' ? '#fa8c15' : '' }} to="/cs-dashboard">
                      <PhoneOutlined style={{ fontSize: '16px' }} />
                      <span><IntlMessages id="sidebar.cs" /></span>
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="responder-dashboard">
                    <Link style={{ color: location.pathname === '/responder-dashboard' ? '#fa8c15' : '' }} to="/responder-dashboard">
                      <i className="icon icon-alert" />
                      <span><IntlMessages id="sidebar.responder" /></span>
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="operation-management">
                    <Link style={{ color: location.pathname === '/operation-management/asset-management' ? '#fa8c15' : '' }} to="/operation-management/asset-management">
                      <ReconciliationOutlined />
                      <span><IntlMessages id="sidebar.inventoryManagement" /></span>
                    </Link>
                  </Menu.Item>

                  <MenuItemGroup key="customer-support-reports" className="gx-menu-group"
                      title={<IntlMessages id="sidebar.reportManagement" />}>
                      <Menu.Item key="customer-support-reports">
                        <Link style={{ color: location.pathname === '/customer-support-reports' ? '#fa8c15' : '' }} to="/customer-support-reports">
                          <i className="icon icon-chart" />
                          <span><IntlMessages id="sidebar.csReports" /></span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="reports">
                        <Link style={{ color: location.pathname === '/reports' ? '#fa8c15' : '' }} to="/reports">
                          <i className="icon icon-chart-line" />
                          <span><IntlMessages id="sidebar.reports" /></span>
                        </Link>
                      </Menu.Item>
                    </MenuItemGroup>

                  <MenuItemGroup key="system-management" className="gx-menu-group"
                    title={<IntlMessages id="sidebar.system-management" />}>

                    <Menu.Item key="system-management/incidents">
                      <Link style={{ color: location.pathname === '/system-management/incidents' ? '#fa8c15' : '' }} to="/system-management/incidents">
                        <i className="icon icon-spam" />
                        <span><IntlMessages id="sidebar.categories" /></span>
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="system-management/departments">
                      <Link style={{ color: location.pathname === '/system-management/departments' ? '#fa8c15' : '' }} to="/system-management/departments">
                        <i className="icon icon-crm" />
                        <span><IntlMessages id="sidebar.departments" /></span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="system-management/barangays">
                      <Link style={{ color: location.pathname === '/system-management/barangays' ? '#fa8c15' : '' }} to="/system-management/barangays">
                        <i className="icon icon-home" /><span>
                          <IntlMessages id="sidebar.barangays" /></span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="system-management/stations">
                      <Link style={{ color: location.pathname === '/system-management/stations' ? '#fa8c15' : '' }} to="/system-management/stations"><i className="icon icon-company" />
                        <span><IntlMessages id="sidebar.stations" /></span></Link>
                    </Menu.Item>
                    <Menu.Item key="operation-management/asset-classifications">
                      <Link style={{ color: location.pathname === '/operation-management/asset-classifications' ? '#fa8c15' : '' }} to="/operation-management/asset-classifications">
                        <ApartmentOutlined />
                        <span><IntlMessages id="sidebar.classifications" /></span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="system-management/status">
                      <Link style={{ color: location.pathname === '/system-management/status' ? '#fa8c15' : '' }} to="/system-management/status">
                        <i className="icon icon-check-circle-o" /><span>
                          <IntlMessages id="sidebar.status" /></span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="system-management/roles">
                      <Link style={{ color: location.pathname === '/system-management/roles' ? '#fa8c15' : '' }} to="/system-management/roles">
                        <i className="icon icon-avatar" /><span>
                          <IntlMessages id="sidebar.roles" /></span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="system-management/system-variables">
                      <Link style={{ color: location.pathname === '/system-management/system-variables' ? '#fa8c15' : '' }} to="/system-management/system-variables">
                        <i className="icon icon-extra-components" />
                        <span><IntlMessages id="sidebar.system-variables" /></span>
                      </Link>
                    </Menu.Item>
                  </MenuItemGroup>
                </>
                :
                userRole === "Management" ?
                  <>
                    <Menu.Item key="dashboard">
                      <Link style={{ color: location.pathname === '/dashboard' ? '#fa8c15' : '' }} to="/dashboard">
                        <i className="icon icon-dasbhoard" />
                        <span><IntlMessages id="sidebar.dashboard" /></span>
                      </Link>
                    </Menu.Item>

                    <MenuItemGroup key="operation-management" className="gx-menu-group"
                      title={<IntlMessages id="sidebar.operation-management" />}>
                      <Menu.Item key="responder-dashboard">
                        <Link style={{ color: location.pathname === '/responder-dashboard' ? '#fa8c15' : '' }} to="/responder-dashboard">
                          <i className="icon icon-alert" />
                          <span><IntlMessages id="sidebar.responder" /></span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="operation-management">
                        <Link style={{ color: location.pathname === '/operation-management/asset-management' ? '#fa8c15' : '' }} to="/operation-management/asset-management">
                          <ReconciliationOutlined />
                          <span><IntlMessages id="sidebar.inventoryManagement" /></span>
                        </Link>
                      </Menu.Item>

                      {/* <Menu.Item key="operation-management/departments">
                        <Link to="/operation-management/departments">
                          <i className="icon icon-crm" />
                          <span><IntlMessages id="sidebar.departments" /></span>
                        </Link>
                      </Menu.Item> */}
                      {/* <Menu.Item key="operation-management/barangays">
                        <Link to="/operation-management/barangays">
                          <i className="icon icon-home" /><span>
                          <IntlMessages id="sidebar.barangays" /></span>
                        </Link>
                      </Menu.Item> */}
                      <Menu.Item key="operation-management/stations">
                        <Link style={{ color: location.pathname === '/operation-management/stations' ? '#fa8c15' : '' }} to="/operation-management/stations"><i className="icon icon-company" />
                          <span><IntlMessages id="sidebar.stations" /></span></Link>
                      </Menu.Item>
                    </MenuItemGroup>

                    <MenuItemGroup key="customer-support-reports" className="gx-menu-group"
                      title={<IntlMessages id="sidebar.reportManagement" />}>
                      <Menu.Item key="reports">
                        <Link style={{ color: location.pathname === '/reports' ? '#fa8c15' : '' }} to="/reports">
                          <i className="icon icon-chart-line" />
                          <span><IntlMessages id="sidebar.reports" /></span>
                        </Link>
                      </Menu.Item>
                    </MenuItemGroup>
                  </>
                  :
                  userRole === "Operation" ?
                    <>
                      <Menu.Item key="dashboard">
                        <Link style={{ color: location.pathname === '/dashboard' ? '#fa8c15' : '' }} to="/dashboard">
                          <i className="icon icon-dasbhoard" />
                          <span><IntlMessages id="sidebar.dashboard" /></span>
                        </Link>
                      </Menu.Item>

                      <MenuItemGroup key="operation-management" className="gx-menu-group"
                        title={<IntlMessages id="sidebar.operation-management" />}>
                        <Menu.Item key="responder-dashboard">
                          <Link style={{ color: location.pathname === '/responder-dashboard' ? '#fa8c15' : '' }} to="/responder-dashboard">
                            <i className="icon icon-alert" />
                            <span><IntlMessages id="sidebar.responder" /></span>
                          </Link>
                        </Menu.Item>
                        <Menu.Item key="operation-management">
                          <Link style={{ color: location.pathname === '/operation-management/asset-management' ? '#fa8c15' : '' }} to="/operation-management/asset-management">
                            <ReconciliationOutlined />
                            <span><IntlMessages id="sidebar.inventoryManagement" /></span>
                          </Link>
                        </Menu.Item>
                        {/* <Menu.Item key="operation-management/categories">
                          <Link to="/operation-management/categories">
                            <i className="icon icon-spam" />
                            <span><IntlMessages id="sidebar.categories" /></span>
                          </Link>
                        </Menu.Item> */}

                        {/* <Menu.Item key="operation-management/departments">
                        <Link to="/operation-management/departments">
                          <i className="icon icon-crm" />
                          <span><IntlMessages id="sidebar.departments" /></span>
                        </Link>
                      </Menu.Item> */}
                        {/* <Menu.Item key="operation-management/barangays">
                        <Link to="/operation-management/barangays">
                          <i className="icon icon-home" /><span>
                          <IntlMessages id="sidebar.barangays" /></span>
                        </Link>
                      </Menu.Item> */}
                        <Menu.Item key="operation-management/stations">
                          <Link style={{ color: location.pathname === '/operation-management/stations' ? '#fa8c15' : '' }} to="/operation-management/stations"><i className="icon icon-company" />
                            <span><IntlMessages id="sidebar.stations" /></span></Link>
                        </Menu.Item>
                        {/* <Menu.Item key="operation-management/asset-classifications">
                          <Link to="/operation-management/asset-classifications">
                            <ApartmentOutlined />
                            <span><IntlMessages id="sidebar.classifications" /></span>
                          </Link>
                        </Menu.Item> */}
                      </MenuItemGroup>
                    </>
                    : null
            }

            {/* <Menu.Item key="dashboard">
              <Link to="/dashboard">
                <i className="icon icon-dasbhoard" />
                <span><IntlMessages id="sidebar.dashboard" /></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="responder-dashboard">
              <Link to="/responder-dashboard">
                <i className="icon icon-alert" />
                <span><IntlMessages id="sidebar.responder" /></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="cs-dashboard">
              <Link to="/cs-dashboard">
                <PhoneOutlined style={{ fontSize: '20px' }} />
                <span><IntlMessages id="sidebar.cs" /></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="asset-management">
              <Link to="/asset-management">
                <ReconciliationOutlined style={{ fontSize: '20px' }} />
                <span><IntlMessages id="sidebar.inventoryManagement" /></span>
              </Link>
            </Menu.Item>

            <MenuItemGroup key="reports" className="gx-menu-group"
              title={<IntlMessages id="sidebar.reportManagement" />}>
              <Menu.Item key="reports">
                <Link to="/reports">
                  <i className="icon icon-chart-line" />
                  <span><IntlMessages id="sidebar.reports" /></span>
                </Link>
              </Menu.Item>
              <Menu.Item key="responder-report">
                <Link to="/responder-report">
                  <i className="icon icon-chart" />
                  <span><IntlMessages id="sidebar.activeResponderReport" /></span>
                </Link>
              </Menu.Item>
            </MenuItemGroup>

            <MenuItemGroup key="system-management" className="gx-menu-group"
              title={<IntlMessages id="sidebar.system-management" />}>
              <Menu.Item key="system-management/status">
                <Link to="/system-management/status">
                  <i className="icon icon-check-circle-o" /><span>
                  <IntlMessages id="sidebar.status" /></span>
                </Link>
              </Menu.Item>

              <Menu.Item key="system-management/roles">
                <Link to="/system-management/roles">
                  <i className="icon icon-avatar" /><span>
                  <IntlMessages id="sidebar.roles" /></span>
                </Link>
              </Menu.Item>

              <Menu.Item key="system-management/categories">
                <Link to="/system-management/categories">
                  <i className="icon icon-spam" />
                  <span><IntlMessages id="sidebar.categories" /></span>
                </Link>
              </Menu.Item>

              <Menu.Item key="system-management/departments">
                <Link to="/system-management/departments">
                  <i className="icon icon-crm" />
                  <span><IntlMessages id="sidebar.departments" /></span>
                </Link>
              </Menu.Item>

              <Menu.Item key="system-management/barangays">
                <Link to="/system-management/barangays">
                  <i className="icon icon-home" /><span>
                  <IntlMessages id="sidebar.barangays" /></span>
                </Link>
              </Menu.Item>

              <Menu.Item key="system-management/stations">
                <Link to="/system-management/stations"><i className="icon icon-company" />
                  <span><IntlMessages id="sidebar.stations" /></span></Link>
              </Menu.Item>

              <Menu.Item key="system-management/asset-classifications">
                <Link to="/system-management/asset-classifications">
                  <ApartmentOutlined />
                  <span><IntlMessages id="sidebar.classifications" /></span>
                </Link>
              </Menu.Item>

              <Menu.Item key="system-management/system-variables">
                <Link to="/system-management/system-variables">
                  <i className="icon icon-extra-components" />
                  <span><IntlMessages id="sidebar.system-variables" /></span>
                </Link>
              </Menu.Item>
            </MenuItemGroup> */}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);

