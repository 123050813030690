import React,{useEffect} from "react";
import { Button, Divider, Form, Input, message } from "antd";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { useAuth } from "../authentication";
// import AppNotificationContainer from "../components/AppNotificationContainer";
import SFLogo from "../assets/images/sf_logo.svg";
import CircularProgress from "../components/CircularProgress";
import EmailVerification from "./EmailVerification";
import ChangeDefaultPassword from "./ChangeDefaultPassword";

const SignIn = () => {
  const { isLoading, error, userLogin } = useAuth();

  useEffect(() => {
    if (error === false) {
      //SHOW EMAIL VALIDATION
    }
    else if(error === true){
      //SHOW CHECK DEFAULT PASSWORD
    }
    else if(error){
      message.error(error)
    }
  }, [error]);

  const onFinishFailed = errorInfo => {
  };

  const onFinish = values => {
    userLogin(values);
  };

  return (
    <div className="gx-app-login-wrap loginBackground">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
            </div>
            <div className="gx-app-logo-wid gx-text-center">
              <img alt="avatar" src={SFLogo} width="90%" />
            </div>
            <div className="gx-app-logo gx-text-center" style={{ marginTop: "30px" }}>
              <h2 style={{ color: "white" }}>City of San Fernando</h2>
              <h3 style={{ color: "white" }}>Emergency Response Core</h3>
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0">
              <h2 className="gx-mb-3"><IntlMessages id="app.userAuth.signIn" /></h2>
              <Divider style={{ marginBottom: "50px" }} />
              <Form.Item
                rules={[{ required: true, message: 'The input is not valid Username!' }]} name="username">
                <Input placeholder="Username" />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: 'Please input your Password!' }]} name="password">
                <Input type="password" placeholder="Password" />
              </Form.Item>
              <Form.Item>
                <span className="gx-link"><Link to="/forgot-password">Unable to access your account?</Link></span>
              </Form.Item>
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>
              </Form.Item>
            </Form>
          </div>
          {/* <AppNotificationContainer loading={isLoading} error={error} /> */}
          {isLoading ?
            <div className="gx-loader-view">
              <CircularProgress/>
            </div> : null}
            {error === false ? <EmailVerification /> : error === true ? <ChangeDefaultPassword /> : null}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
